import { am } from './utils';

import httpClient from '@/utils/httpClient';

const GET_COLLECTION = am(`GET_LIST`);
const GET_DETAILS = am(`GET_LIST_ITEM`);
const UPDATE_INVENTION = am(`UPDATE_INVENTION`);

export const MILESTONE_UPDATE = am(`MILESTONE_UPDATE`);

const defaultSettings = {
  formTitle: 'Milestones Overview Settings',
  source: 'milestonesOverview',
  version: '1',
  name: 'MILESTONE_OVERVIEW_SETTINGS',
  taskViewMode: {
    title: 'Next task mode',
    options: ['Next Task', 'My Next Task'],
    value: 'Next Task'
  },
  colorCoding: {
    title: 'Color coding',
    options: ['On', 'Off'],
    code: 'MILESTONE_OVERVIEW_SHOW_COLORS',
    value: 'On'
  },
  displayMode: {
    title: 'Display mode',
    type: 'switch',
    options: ['Standard', 'Compact', 'Compact + Notes'],
    value: 'Standard'
  },
  columns: {
    title: 'Columns',
    description: 'Show/Hide columns',
    type: 'multiselect',
    value: [
      { title: 'Client Date', value: true, disabled: true },
      { title: 'Due Date', value: true, disabled: true },
      { title: 'Next 2AR', value: false }
    ]
  }
};

const isActive = m => {
  if (m.deleted) {
    return false;
  }

  if (m.finished) {
    return false;
  }

  return true;
};

const isFinished = m => {
  return m.finished ? true : false;
};

const sortMilestones = (a, b) => {
  if (!a.id && !b.id) {
    return 0;
  }

  if (!b.id) {
    return 1;
  }

  if (!a.id) {
    return -1;
  }

  if (isActive(a) && isActive(a) === isActive(b)) {
    return new Date(a.dueAt).getTime() - new Date(b.dueAt).getTime();
  }

  if (isActive(a)) {
    return -1;
  }

  if (isActive(b)) {
    return 1;
  }

  if (isFinished(a) === isFinished(b)) {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  }

  if (isFinished(a)) {
    return -1;
  }

  if (isFinished(b)) {
    return 1;
  }

  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
};

const state = {
  total: 0,
  from: 0,
  data: [],
  isRequestPending: false,
  isRequestFailed: true,
  expanded: null,
  defaultSettings,
  details: {}
};
const getters = {
  milestonesByDetailsId: state => id => {
    return state.details[id].milestones;
  }
};

const mutations = {
  [GET_COLLECTION.STARTED](state, { initialize, refresh }) {
    if (!refresh && initialize) {
      state.isRequestPending = true;
    }
    state.isRequestFailed = false;
    if (initialize) {
      state.data = [];
    }
  },
  [GET_COLLECTION.COMPLETED](state, { data, total, refresh }) {
    state.isRequestPending = false;
    if (refresh) {
      state.data = data;
    } else {
      for (const item of data) {
        const index = state.data.findIndex(({ id, nextMilestone }) => item.id === id && item.nextMilestone?.id === nextMilestone.id);
        if (index > -1) {
          state.data[index] = item;
        } else {
          state.data.push(item);
        }
      }
    }

    state.total = total;
  },
  [GET_COLLECTION.FAILED](state) {
    state.isRequestPending = false;
    state.isRequestFailed = true;
  },
  [GET_DETAILS.STARTED](state, { id }) {
    state.details[id] = {
      isRequestPending: true,
      isRequestFailed: false,
      milestones: []
    };
  },
  [GET_DETAILS.COMPLETED](state, { id, milestones }) {
    if (!state.details[id]) {
      state.details[id] = {};
    }

    state.details[id].isRequestPending = false;
    state.details[id].isRequestFailed = false;
    state.details[id].milestones = milestones;
  },
  [GET_DETAILS.FAILED](state, { id }) {
    if (!state.details[id]) {
      state.details[id] = {};
    }

    state.details[id].isRequestPending = false;
    state.details[id].isRequestFailed = true;
  },
  ['GET_DETAILS.RESET'](state) {
    state.details = {};
  },
  [MILESTONE_UPDATE.STARTED](state, { inventionId, milestoneId }) {
    const milestone = state.details[inventionId].milestones.find(m => m.workflow.milestoneId === milestoneId);
    milestone.isUpdating = true;
  },
  [MILESTONE_UPDATE.COMPLETED](state, { inventionId, milestoneId }) {
    const milestone = state.details[inventionId].milestones.find(m => m.workflow.milestoneId === milestoneId);
    milestone.isUpdating = false;
  },
  [UPDATE_INVENTION.STARTED](state, { inventionId, milestoneId }) {
    const invention = state.data?.find(i => i.id === inventionId && i.nextMilestone.id === milestoneId);

    if (invention) {
      invention.updating = true;
    }
  },
  [UPDATE_INVENTION.COMPLETED](state, { newInvention }) {
    const invention = state.data?.find(i => i.id === newInvention.id && i.nextMilestone.id === newInvention.nextMilestone.id);

    if (invention) {
      invention.nextTask = newInvention.nextTask;
      invention.myNextTask = newInvention.myNextTask;
      invention.nextMilestone = newInvention.nextMilestone;
      invention.title = newInvention.title;
      invention.references = newInvention.references;
      invention.updating = false;
    }
  },
  [UPDATE_INVENTION.FAILED](state, { inventionId }) {
    const invention = state.data?.find(i => i.id === inventionId);
    if (invention) {
      invention.updating = false;
    }
  },
  'DETAILS.EXPAND'(state, id) {
    state.expanded = id;
  },
  taskCreated(state, item) {
    const invention = state.details[item.inventionId];

    if (!invention) {
      return;
    }

    let milestones = invention?.milestones;

    if (!milestones) {
      milestones = [];
      invention.milestones = milestones;
    }

    const task = item.metadata.task;
    let milestone = milestones.find(m => m.id === task.workflow?.milestoneId ?? null);

    if (!milestone) {
      if (!task.workflow?.milestoneId) {
        milestone = {
          id: null,
          title: 'No Milestone',
          expanded: true,
          tasks: []
        };
        milestones.unshift(milestone);
      } else {
        return;
      }
    }
    const taskIndex = milestone.tasks.sort((a, b) => a.order - b.order).findIndex(t => t.id === task.id);
    if (taskIndex > -1) {
      return;
    }

    milestone.tasks.push(task);
  },
  taskFetched(state, { inventionId, task }) {
    const milestones = state.details[inventionId]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const milestone = milestones.find(m => m.id === task.workflow.milestoneId);

    if (!milestone) {
      return;
    }

    if (!milestone.tasks) {
      milestone.tasks = [];
    }

    const taskIndex = milestone.tasks.findIndex(t => t.id === task.id);
    if (taskIndex > -1) {
      milestone.tasks.splice(taskIndex, 1, task);
    }
    milestone.tasks.sort((a, b) => a.order - b.order);
  },
  taskUpdated(state, task) {
    const milestones = state.details[task.invention.id]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const milestone = milestones.find(m => m.id === task.workflow.milestoneId);

    if (!milestone) {
      return;
    }
    const taskIndex = milestone.tasks.findIndex(t => t.id === task.id);
    if (taskIndex > -1) {
      milestone.tasks[taskIndex] = task;
    } else {
      const previousMilestone = milestones.find(m => m.tasks?.some(t => t.id === task.id));
      const index = previousMilestone?.tasks?.findIndex(t => t.id === task.id);
      if (index > -1) {
        previousMilestone.tasks.splice(index, 1);
      }
      milestone.tasks.push(task);
    }

    if (state.data?.length) {
      const invention = state.data.find(
        c => (c.nextTask?.id === task.id || c.myNextTask?.id === task.id) && task.workflow.milestoneId === c.nextMilestone.id
      );
      if (!invention) {
        return;
      }

      const taskToUpdate = invention.nextTask?.id === task.id ? invention.nextTask : invention.myNextTask;

      const { title, dueAt, assignees, notes, status } = task;
      taskToUpdate.title = title;
      taskToUpdate.dueAt = dueAt;
      taskToUpdate.assignees = assignees;
      taskToUpdate.notes = notes;
      taskToUpdate.status = status;
    }
  },
  milestoneUpdated(state, item) {
    const milestones = state.details[item.inventionId]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const milestone = milestones.find(m => m.id === item.metadata.id);

    if (!milestone) {
      return;
    }

    const { id, title, dueAt, assignees, clientDueDate, workflow, diffs } = item.metadata;
    const deletedUpdate = diffs.find(d => d.originalProperty === 'deleted');
    if (deletedUpdate) {
      milestone.deleted = deletedUpdate.newValue.toLowerCase() === 'true';
    }

    const finishedUpdate = diffs.find(d => d.originalProperty === 'finished');
    if (finishedUpdate) {
      milestone.finished = finishedUpdate.newValue.toLowerCase() === 'true';
    }

    milestone.completed = milestone.finished || milestone.deleted;
    milestone.id = id;
    milestone.title = title;
    milestone.dueAt = dueAt;
    milestone.clientDueDate = clientDueDate;
    milestone.assignees = assignees;
    milestone.workflow = workflow;
  },
  milestoneCreated(state, item) {
    const invention = state.details[item.inventionId];

    if (!invention) {
      return;
    }

    if (!invention.milestones) {
      invention.milestones = [];
    }

    const { id, title, dueAt, assignees, clientDueDate, workflow } = item.metadata;

    const newItem = {
      id,
      dueAt,
      title,
      clientDueDate,
      assignees,
      expanded: true,
      finished: false,
      completed: false,
      deleted: false,
      isUpdating: true,
      tasks: [],
      workflow
    };
    invention.milestones.splice(1, 0, newItem);
  },
  milestoneFetched(state, item) {
    const milestones = state.details[item.inventionId]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const milestone = milestones.find(m => m.id === item.workflow?.milestoneId);
    if (milestone) {
      milestone.isUpdating = false;
      milestone.workflow = item.workflow;
      milestone.nextTask = item.nextTask;
    }
  },
  milestoneFinished(state, { inventionId, milestoneId }) {
    const milestones = state.details[inventionId]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const milestone = milestones.find(m => m.id === milestoneId);
    milestone.finished = true;
    milestone.completed = true;
    milestone.expanded = false;

    state.details[inventionId].milestones = state.details[inventionId].milestones.sort(sortMilestones);
  },
  milestoneCancelled(state, { inventionId, milestoneId }) {
    const milestones = state.details[inventionId]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const milestone = milestones.find(m => m.id === milestoneId);
    milestone.deleted = true;
    milestone.completed = true;
    milestone.expanded = false;
    state.details[inventionId].milestones = state.details[inventionId].milestones.sort(sortMilestones);
  }
};
const actions = {
  expand({ commit }, id) {
    commit('DETAILS.EXPAND', id);
  },
  async updateInvention({ commit, state }, { inventionId, milestoneId }) {
    try {
      const invention = state.data?.find(i => i.id === inventionId);

      if (!invention) {
        return;
      }

      commit(UPDATE_INVENTION.STARTED, { inventionId });

      const newInvention = await httpClient.get(`/api/search/overview/inventions/${inventionId}?milestoneId=${milestoneId}`);
      commit(UPDATE_INVENTION.COMPLETED, { newInvention });
    } catch (error) {
      commit(UPDATE_INVENTION.FAILED, inventionId);
    }
  },
  async getCollection({ commit }, { assignee, filter, skip, sort, size, refresh, tags } = {}) {
    try {
      commit(GET_COLLECTION.STARTED, {
        initialize: typeof skip === 'undefined' && !refresh,
        refresh
      });

      const params = new URLSearchParams();
      if (skip) {
        params.set('skip', skip);
      }

      if (filter) {
        params.set('qs', filter);
      }

      if (assignee) {
        params.set('assignee', assignee);
      }

      if (sort) {
        params.set('sort', sort);
      }
      if (size) {
        params.set('size', size);
      } else {
        params.set('size', 50);
      }

      let paramsString = params.toString();

      if (tags && tags.length) {
        const tagsString = tags.map(tag => `tags=${tag}`).join('&');
        paramsString += `&${tagsString}`;
      }

      const response = await httpClient.get(`/api/search/overview/milestones?` + paramsString);

      commit(GET_COLLECTION.COMPLETED, {
        data: response.data,
        total: response.total,
        refresh
      });
    } catch (e) {
      commit(GET_COLLECTION.FAILED);
      throw e;
    }
  },
  async getDetails({ commit }, { id, assignee }) {
    try {
      commit(GET_DETAILS.STARTED, {
        id
      });

      const params = new URLSearchParams();

      if (assignee) {
        params.set('assignee', assignee);
      }

      const response = await httpClient.get(`/api/search/overview/inventions/${id}/milestones?${params.toString()}`);

      commit(GET_DETAILS.COMPLETED, {
        id,
        milestones: response
          .map(milestone => ({
            ...milestone,
            completed: milestone.finished || milestone.deleted,
            expanded: !(milestone.finished || milestone.deleted),
            tasks: Array.isArray(milestone.tasks) ? milestone.tasks : []
          }))
          .sort(sortMilestones)
      });
    } catch (e) {
      commit(GET_DETAILS.FAILED, { id });
      throw e;
    }
  },
  resetDetails({ commit }) {
    commit('GET_DETAILS.RESET');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
