import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';

const MESSAGE_LOAD_STREAM = am(`MESSAGE_LOAD_STREAM`);

export default {
  namespaced: true,
  ...factory(
    {
      state: {
        id: null,
        total: 0,
        from: 0,
        data: [],
        isRequestPending: false,
        isRequestFailed: true
      },
      getters: {
        collection: state => {
          return state.data.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
        }
      },
      mutations: {
        [MESSAGE_LOAD_STREAM.STARTED](state, { id, initialize }) {
          state.id = id;
          state.isRequestPending = true;
          state.isRequestFailed = false;
          if (initialize) {
            state.data = [];
          }
        },
        [MESSAGE_LOAD_STREAM.COMPLETED](state, { data, total }) {
          state.isRequestPending = false;

          for (const item of data) {
            const existing = state.data.find(({ id }) => item.id === id);
            if (item.chunk && existing && !existing.message.endsWith(item.message)) {
              existing.message += item.message;
              continue;
            }
            if (existing) {
              continue;
            }
            state.data.unshift(item);
          }
          state.total = total;
        },
        [MESSAGE_LOAD_STREAM.FAILED](state) {
          state.isRequestPending = false;
          state.isRequestFailed = true;
        }
      },
      actions: {
        async load({ commit }, { id, skip, from, to }) {
          try {
            commit(MESSAGE_LOAD_STREAM.STARTED, {
              id,
              initialize: typeof skip === 'undefined'
            });

            const params = new URLSearchParams();
            if (skip) {
              params.set('skip', skip);
            }
            if (from) {
              params.set('from', from);
            }
            if (to) {
              params.set('to', to);
            }

            params.set('size', 25);

            const response = await httpClient.get(`/api/streams/chat/${id}/messages`);

            commit(MESSAGE_LOAD_STREAM.COMPLETED, {
              data: response.data.map(item => ({ ...item, createdAt: new Date(item.createdAt) })),
              total: response.total
            });
          } catch (e) {
            commit(MESSAGE_LOAD_STREAM.FAILED);
            throw e;
          }
        },
        'stream:chat.message'({ commit }, item) {
          commit(MESSAGE_LOAD_STREAM.COMPLETED, {
            data: [item]
          });
        }
      }
    },
    {
      path: '/api/streams/socket.io',
      actions: {
        subscribe: 'join',
        unsubscribe: 'leave'
      },
      handlers: ['stream:chat.message']
    }
  )('messages', {
    async getCollection() {
      throw new Error('Not implemented.');
    },
    async create({ inventionId, message }) {
      return await httpClient.post(`/api/streams/chat/${inventionId}/messages`, { message });
    },
    async delete() {
      throw new Error('Not implemented.');
    },
    async update() {
      throw new Error('Not implemented.');
    }
  })
};
