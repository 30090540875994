import { createApp, h } from 'vue';
import Confirm from './../components/common/Confirm';

export default {
  install(app) {
    app.config.globalProperties.$confirm = props => {
      return new Promise(resolve => {
        const closeWithResult = result => {
          instance.unmount();
          resolve(result);
        };

        const instance = createApp({
          render() {
            return h(Confirm, {
              ...props,
              onCancel() {
                closeWithResult();
              },
              onConfirm() {
                closeWithResult(true);
              }
            });
          }
        });

        instance.mount(document.getElementById('modal'));
      });
    };
  }
};
