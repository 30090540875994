import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';

const EMAIL_LOAD_STREAM = am(`EMAIL_LOAD_STREAM`);

export default {
  namespaced: true,
  ...factory(
    {
      state: {
        id: null,
        total: 0,
        from: 0,
        data: [],
        isRequestPending: false,
        isRequestFailed: true
      },
      getters: {
        collection: state => {
          return state.data.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
        }
      },
      mutations: {
        [EMAIL_LOAD_STREAM.STARTED](state, { id, initialize }) {
          state.id = id;
          state.isRequestPending = true;
          state.isRequestFailed = false;
          if (initialize) {
            state.data = [];
          }
        },
        [EMAIL_LOAD_STREAM.COMPLETED](state, { data, total }) {
          state.isRequestPending = false;

          for (const item of data) {
            if (state.data.find(({ id }) => item.id === id)) {
              continue;
            }
            state.data.unshift(item);
          }

          state.total = total;
        },
        [EMAIL_LOAD_STREAM.FAILED](state) {
          state.isRequestPending = false;
          state.isRequestFailed = true;
        }
      },
      actions: {
        async load({ commit }, { id, skip, from, to }) {
          try {
            commit(EMAIL_LOAD_STREAM.STARTED, {
              id,
              initialize: typeof skip === 'undefined'
            });

            const params = new URLSearchParams();
            if (skip) {
              params.set('skip', skip);
            }
            if (from) {
              params.set('from', from);
            }
            if (to) {
              params.set('to', to);
            }

            params.set('size', 25);

            const response = await httpClient.get(`/api/hub-correspondence/correspondence/${id}/messages?${params.toString()}`);

            commit(EMAIL_LOAD_STREAM.COMPLETED, {
              data: response.data.map(item => ({ ...item, createdAt: new Date(item.metadata.date) })),
              total: response.total
            });
          } catch (e) {
            commit(EMAIL_LOAD_STREAM.FAILED);
            throw e;
          }
        },
        'stream:added'({ commit, state }, item) {
          commit(EMAIL_LOAD_STREAM.COMPLETED, {
            data: [{ ...item, createdAt: new Date(item.createdAt) }].filter(
              item => item.inventionId === state.id && item.type === 'correspondence:created'
            ),
            total: state.total + 1
          });
        }
      }
    },
    {
      path: '/api/streams/socket.io',
      actions: {
        subscribe: 'join',
        unsubscribe: 'leave'
      },
      handlers: ['stream:added']
    }
  )('EMAILs', {
    async getCollection() {
      throw new Error('Not implemented.');
    },
    async create() {
      throw new Error('Not implemented.');
    },
    async delete() {
      throw new Error('Not implemented.');
    },
    async update() {
      throw new Error('Not implemented.');
    }
  })
};
