<template>
  <button :type="type" :class="[color, variant]" v-bind="$attrs" @click="onclick">
    <slot />
  </button>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    color: {
      type: String,
      default: '',
      validator: function (value) {
        return ['', 'primary', 'secondary'].indexOf(value) !== -1;
      }
    },
    variant: {
      type: String,
      default: 'contained',
      validator(value) {
        return ['contained', 'text', 'icon'].indexOf(value) !== -1;
      }
    },
    type: {
      type: String,
      default: 'submit'
    }
  },
  emits: ['click'],
  methods: {
    onclick(e) {
      this.$emit('click', e);
    }
  }
};
</script>

<style lang="scss" scoped="true">
button {
  outline: none;
  padding: 8px 12px;
  font-size: 0.85rem;
  letter-spacing: 0.025em;
  font-weight: 700;
  border-radius: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &.contained {
    color: var(--theme-on-default);
    background-color: transparent;
    border: 1px solid transparent;

    &:not([disabled]) {
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    &.primary {
      color: var(--theme-on-primary);
      background-color: var(--theme-primary);
      border-color: var(--theme-primary);

      &:not([disabled]) {
        &:hover {
          background-color: var(--theme-primary-accent);
          border-color: var(--theme-primary-accent);
        }

        &:focus {
          box-shadow: 0px 0px 2px 1px var(--theme-primary-accent);
        }
      }
    }

    &.secondary {
      color: var(--theme-on-secondary);
      background-color: var(--theme-secondary);
      border-color: var(--theme-secondary);

      &:not([disabled]) {
        &:hover {
          background-color: var(--theme-secondary-accent);
          border-color: var(--theme-secondary-accent);
        }

        &:focus {
          box-shadow: 0px 0px 1px 1px var(--theme-secondary-accent);
        }
      }
    }

    &:not([disabled]) {
      &:hover {
        cursor: pointer;
        // background-color: var(--theme-default-dark);
      }
    }
  }

  &.text {
    border: 1px solid transparent;
    background-color: transparent;
    color: var(--theme-on-background);
    padding: 0.05rem 0.5rem;
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:not([disabled]) {
      &:hover {
        cursor: pointer;
      }
    }

    &.primary {
      color: var(--theme-primary);
    }

    &.secondary {
      color: var(--theme-secondary);
    }
  }

  &.icon {
    // background: red;
    border-radius: 50%;
    padding: 5px;
    border: 0;
    background: transparent;
    color: inherit;

    &:not([disabled]) {
      &:hover {
        // box-shadow: 0 0 0 1px var(--theme-highlight);

        color: var(--theme-on-background-accent);
      }
    }

    &.primary {
      color: var(--theme-primary);

      &:not([disabled]) {
        &:hover {
          color: var(--theme-primary-accent);
        }
      }
    }

    &.secondary {
      color: var(--theme-secondary);

      &:not([disabled]) {
        &:hover {
          color: var(--theme-secondary-accent);
        }
      }
    }
  }
}
</style>
