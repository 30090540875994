<template>
  <span class="checkbox">
    <span @click.stop="() => {}">
      <input :id="id" type="checkbox" :checked="value" :disabled="disabled" :value="value" @change="toggle" />
      <label :for="id">
        <span class="checkbox-image" :class="{ disabled }">
          <span v-if="value">&#10003;</span>
        </span>
      </label>
    </span>
    <span v-if="!!label" :class="{ disabled }">{{ label }} </span>
  </span>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: () => Math.random().toString()
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'change', 'update:value', 'update:modelValue'],
  methods: {
    toggle(event) {
      if (!event.target.checked) {
        this.uncheck();
      } else {
        this.check();
      }
    },
    check() {
      this.$emit('input', true);
      this.$emit('change', true);
      // this.$emit('update:modelValue', true);
      this.$emit('update:value', true);
    },
    uncheck() {
      this.$emit('input', false);
      this.$emit('change', false);
      // this.$emit('update:modelValue', false);
      this.$emit('update:value', false);
    }
  }
};
</script>

<style scoped lang="scss">
.checkbox {
  display: inline-flex;
  align-items: center;
  input {
    width: 0;
    height: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
  }

  label {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;

    .checkbox-image {
      display: inline-flex;
      border: 1px solid;
      border-radius: 2px;
      width: 1rem;
      height: 1rem;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-right: 5px;
      cursor: pointer;

      > span {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1rem;
        height: 1rem;
        transform: translate(-50%, -50%);
        justify-content: center;
        align-items: center;
        display: inline-flex;
        font-size: 0.85rem;
      }

      & ~ span {
        margin-left: 5px;
        font-weight: 500;
        font-size: 0.85rem;
        letter-spacing: 0.025em;
      }
    }
  }
}
</style>
