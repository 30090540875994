import httpClient from './httpClient';
import logger from './logger';

export async function get(key) {
  try {
    return await httpClient.get(`/api/settings/${key}`);
  } catch (e) {
    logger.error(e);
    return null;
  }
}

export async function set(key, value) {
  try {
    return await httpClient.put(`/api/settings/${key}`, value);
  } catch (e) {
    logger.error(e);
    return null;
  }
}

export default {
  get,
  set
};
