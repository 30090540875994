<template>
  <section class="pane">
    <header>
      <h3>{{ title }}</h3>
      <hub-button variant="icon" class="close-button" @click="$emit('close')"><hub-icon name="close"></hub-icon></hub-button>
    </header>
    <section>
      <component :is="component" v-if="component" :data="data" :show-save-with-status-buttons="true" @close="$event => $emit('close', $event)" />
    </section>
  </section>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import Loading from '@/components/common/Loading';

export default {
  components: {
    'hub-button': Button,
    'hub-icon': Icon
  },
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      default: () => null
    }
  },
  emits: ['close'],
  computed: {
    component() {
      if (this.name) {
        return defineAsyncComponent({
          loader: () => import(`./${this.name}`),
          loadingComponent: Loading
        });
      }

      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 1rem;
  user-select: none;
}
.pane {
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  height: 100%;
  width: 100%;

  header {
    display: grid;
    grid-template-rows: minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr) max-content;
    gap: 10px;
    align-items: center;
    border-bottom: 1px solid var(--theme-highlight);
    padding: 5px 5px 5px 10px;
    letter-spacing: 0.02rem;
  }

  > section {
    overflow-y: auto;
    padding: 5px 5px 5px 10px;
    position: relative;
  }
}
</style>
