import logger from '@/utils/logger';

export default {
  install(app) {
    if (window.mixpanel) {
      app.config.globalProperties.$trackEvent = (eventName, props = {}) => window.mixpanel.track(eventName, props);
      app.config.globalProperties.$identify = identity => window.mixpanel.identify(identity);
    } else {
      app.config.globalProperties.$trackEvent = (eventName, props = {}) => logger.info(`Track event: '${eventName}'`, props); // eslint-disable-line
      app.config.globalProperties.$identify = identity => logger.info(`Identify: '${identity}'`); // eslint-disable-line
    }
  }
};
