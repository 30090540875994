import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';

const PREVIEW_FILTER = am(`PREVIEW_FILTER`);
const PREVIEW_LOAD_MORE = am(`PREVIEW_LOAD_MORE`);

export default {
  namespaced: true,
  ...factory({
    state: {
      tasks: {
        isGetCollectionRequestPending: false,
        isLoadingMore: false,
        collection: [],
        references: [],
        total: 0,
        skip: 0,
        size: 0
      }
    },
    mutations: {
      [PREVIEW_LOAD_MORE.STARTED](state) {
        state.tasks.isLoadingMore = true;
      },
      [PREVIEW_LOAD_MORE.COMPLETED](state, collection) {
        state.tasks.isLoadingMore = false;
        if (collection.groupBy) {
          state.tasks.references = collection.references.buckets.map(({ key }) => key);
        }
        if (collection.groupBy) {
          collection.data.forEach(item => {
            const existing = state.tasks.collection.find(c => c.group === item.group);
            if (existing) {
              existing.data.push(...item.data);
            } else {
              state.tasks.collection.push(item);
            }
          });
        } else {
          state.tasks.collection.push(...collection.data);
        }
        state.tasks.total = collection.total;
        // state.tasks.size = collection.size;
        // state.tasks.skip = collection.skip;
      },
      [PREVIEW_FILTER.STARTED](state) {
        state.tasks.isGetCollectionRequestPending = true;
      },
      [PREVIEW_FILTER.COMPLETED](state, collection) {
        state.tasks.isGetCollectionRequestPending = false;
        state.tasks.collection = collection.data;
        state.tasks.total = collection.total;
        state.tasks.size = collection.size;
        state.tasks.skip = collection.skip;

        if (collection?.references?.buckets) {
          state.tasks.references = collection.references.buckets.map(({ key }) => key);
        }
      },
      [PREVIEW_FILTER.FAILED](state) {
        state.tasks.isGetCollectionRequestPending = false;
      }
    },
    actions: {
      async preview({ commit }, obj) {
        try {
          if (obj.skip > 0) {
            commit(PREVIEW_LOAD_MORE.STARTED);
          } else {
            commit(PREVIEW_FILTER.STARTED);
          }
          const response = await httpClient.post('/api/search/tasks', obj);
          if (obj.skip > 0) {
            commit(PREVIEW_LOAD_MORE.COMPLETED, { ...response, groupBy: obj.groupBy });
          } else {
            commit(PREVIEW_FILTER.COMPLETED, {
              ...response,
              skip: obj.skip,
              size: obj.size,
              sort: obj.sort
            });
          }
        } catch (e) {
          commit(PREVIEW_FILTER.FAILED);
        }
      }
    },
    getters: {
      system(state, getters, rootState) {
        return [
          {
            id: '9201729614272025',
            title: 'My Tasks',
            layout: 'compact',
            type: 'system',
            data: {
              qs: '',
              dueAt: '',
              status: [],
              assignees: [rootState.identity.email],
              projectAssignees: []
            },
            metadata: []
          },
          {
            id: '9443030870984088',
            title: 'My Projects',
            layout: 'compact',
            type: 'system',
            data: {
              status: [],
              assignees: [],
              projectAssignees: [rootState.identity.email]
            },
            metadata: []
          }
        ];
      },
      team(state, getters, rootState) {
        return Array.isArray(rootState?.identity?.teams)
          ? rootState.identity.teams.map(team => ({
              id: team.id,
              type: 'team',
              layout: 'minimalistic',
              title: team.name,
              data: {
                qs: '',
                dueAt: '',
                status: [],
                assignees: [team.name],
                projectAssignees: []
              },
              metadata: []
            }))
          : [];
      },
      my(state) {
        return state.collection.map(item => ({ ...item, type: 'personal' }));
      },
      all(state, getters) {
        return [...getters.system, ...getters.team, ...getters.my];
      },
      byId: (state, getters) => id => getters.all.find(item => item.id === id)
    }
  })('filters', {
    async getById(id) {
      return httpClient.get(`/api/workflow/filters/${id}`);
    },
    async getCollection() {
      return httpClient.get(`/api/workflow/filters`);
    },
    async create({ title, layout, data }) {
      return httpClient.post(`/api/workflow/filters`, { title, layout, data });
    },
    async delete(id) {
      return httpClient.delete(`/api/workflow/filters/${id}`);
    },
    async update(id, { title, data, layout }) {
      return httpClient.put(`/api/workflow/filters/${id}`, { title, layout, data });
    }
  })
};
