<template>
  <section class="lock">
    <p-loading />
  </section>
</template>

<script>
import Loading from './Loading';

export default {
  components: {
    'p-loading': Loading
  }
};
</script>

<style lang="scss" scoped>
.lock {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9;
}
</style>
