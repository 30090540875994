import myInventions from './myInventions';
import myTasksCount from './myTasksCount';

export default {
  modules: { myInventions, myTasksCount },
  state: {},
  mutations: {},
  actions: {},
  namespaced: true
};
