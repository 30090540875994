import { createApp, h } from 'vue';
import Toast from './../components/common/Toast';

import './ToastService.scss';

export class ToastService {
  get wrapper() {
    const wrapperId = 'toast-list-container';

    let wrapper = document.getElementById(wrapperId);
    if (wrapper) {
      return wrapper;
    }

    const el = document.createElement('ul');
    el.setAttribute('id', wrapperId);
    el.classList.add('toast-list');

    document.body.appendChild(el);

    return el;
  }
  success(props, duration = 3000) {
    this.show(
      {
        ...props,
        type: 'success'
      },
      duration
    );
  }

  error(props, duration = 5000) {
    this.show(
      {
        ...props,
        type: 'error'
      },
      duration
    );
  }

  show(props, duration) {
    const li = document.createElement('li');
    li.classList.add('toast-list-item');
    this.wrapper.appendChild(li);

    const close = () => {
      instance.unmount();
      this.wrapper.removeChild(li);
      timeout && clearTimeout(timeout);
    };

    const instance = createApp({
      render() {
        return h(Toast, {
          ...props,
          onClose() {
            setTimeout(close, 25);
          }
        });
      }
    });
    instance.mount(li);

    let timeout = null;

    if (duration) {
      timeout = setTimeout(close, duration);
    }

    setTimeout(() => li.classList.add('active'), 25);
  }
}

export default {
  install(app) {
    app.config.globalProperties.$toast = new ToastService(app);
  }
};
