import { get, set } from '@/utils/rs';

const getSettings = async defaultValue => {
  const localSettings = (await get(defaultValue.name)) || {};

  const settings = {
    ...defaultValue
  };

  for (const property of Object.getOwnPropertyNames(settings)) {
    if (property === 'version') {
      settings.firstShow = settings['version'] !== localSettings['version'];
      continue;
    }

    const localStorageSetting = localSettings[property];

    if (localStorageSetting?.value === undefined) {
      continue;
    }

    if (Array.isArray(localStorageSetting.value)) {
      settings[property].value.map(setting => {
        setting.value = localStorageSetting.value.find(v => v.title === setting.title)?.value ?? setting.value;
      });
    } else {
      settings[property].value = localStorageSetting.value;
    }
  }

  return settings;
};

const saveSettings = async settings => {
  const settingsValues = {};

  for (const prop of Object.getOwnPropertyNames(settings).filter(n => ['formTitle', 'source', 'name', 'firstShow'].indexOf(n) < 0)) {
    const setting = settings[prop];
    if (setting.type === 'multiselect') {
      if (!settingsValues[prop]?.value) {
        settingsValues[prop] = { value: [] };
      }
      setting.value.map(o => settingsValues[prop].value.push({ title: o.title, value: o.value }));
    } else if (settings[prop].value !== undefined) {
      settingsValues[prop] = { value: settings[prop].value };
    } else {
      settingsValues[prop] = settings[prop];
    }
  }
  await set(settings.name, settingsValues);
};

export const state = {
  visible: false,
  panelShown: false,
  current: null,
  settings: {}
};

export const mutations = {
  'SETTINGS.BUTTON.SHOW'(state, { setting }) {
    state.current = setting;
    state.visible = true;
    state.panelShown = setting.firstShow;
  },
  'SETTINGS.BUTTON.HIDE'(state) {
    state.visible = false;
    state.panelShown = false;
    saveSettings(state.current);

    state.current = null;
  },
  'SETTINGS.PANEL.TOGGLE'(state) {
    state.panelShown = !state.panelShown;

    if (!state.panelShown) {
      saveSettings(state.current);
    }
  },
  ['SETTINGS.CHANGED'](state, { setting, value }) {
    const changedSetting = state.current[setting];

    if (!changedSetting) {
      return;
    }

    if (changedSetting.type === 'multiselect') {
      changedSetting.value.find(v => v.title === value.title).value = value.value;
    } else if (changedSetting.value !== undefined) {
      changedSetting.value = value;
    } else {
      state.current[setting] = value;
    }

    saveSettings(state.current);
  }
};

export const actions = {
  async initialize({ commit }, { defaultValue }) {
    const setting = await getSettings(defaultValue);
    commit('SETTINGS.BUTTON.SHOW', { setting });
  },
  settingChanged({ commit }, { setting, value }) {
    commit('SETTINGS.CHANGED', { setting, value });
  },
  reset({ commit }) {
    commit('SETTINGS.BUTTON.HIDE');
  },
  togglePanel({ commit }) {
    commit('SETTINGS.PANEL.TOGGLE');
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
