<template>
  <div :class="{ size, 'has-label': !!label }">
    <label v-if="!!label" :for="id" :class="{ warning }">{{ label }}</label>
    <textarea
      v-if="multiline"
      :id="id"
      :value="text"
      :placeholder="placeholder"
      :rows="rows"
      :disabled="disabled"
      :spellcheck="spellcheck"
      :autocomplete="autocomplete"
      :data-test-id="testId"
      :class="{ disabled }"
      @change="notify"
      @input="notify"
      @blur="$emit('blur', $event)"
      @keyup="$emit('keyup', $event)"
      @keydown.enter="onEnter($event)"
      @keydown.up="onUp($event)"
      @keydown.down="onDown($event)"
    ></textarea>
    <div v-else class="input-with-x" :class="{ disabled }" :style="[clearButton ? { display: 'inline' } : {}]">
      <input
        :id="id"
        ref="input"
        :type="type"
        :value="text"
        :placeholder="placeholderValue"
        :disabled="disabled"
        :spellcheck="spellcheck"
        :autocomplete="autocomplete"
        :data-test-id="testId"
        v-bind="limits"
        style="padding-right: 20px; margin: 1px"
        @change="notify"
        @input="notify"
        @blur="onBlur"
        @keyup="$emit('keyup', $event)"
        @focus="onfocus"
        @keydown.enter="onEnter($event)"
        @keydown.up="onUp($event)"
        @keydown.down="onDown($event)"
      />
      <hub-button v-if="clearButton && text.length && !disabled" class="x" @click="clear">
        <hub-icon name="close" size="sm" />
      </hub-button>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';

import Icon from './../common/Icon';
import Button from './../common/Button';

export default {
  components: {
    'hub-button': Button,
    'hub-icon': Icon
  },
  props: {
    id: {
      type: String,
      default: () => Math.random().toString()
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    debounce: {
      type: Boolean
    },
    clearButton: {
      type: Boolean
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    multiline: {
      type: Boolean,
      default: () => false
    },
    size: {
      type: String,
      default: ''
    },
    rows: {
      type: Number,
      default: 5
    },
    type: {
      type: String,
      default: 'text'
    },
    limits: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    spellcheck: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    testId: {
      type: String,
      default: ''
    },
    warning: {
      type: Boolean,
      default: false
    },
    initialFocus: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'change', 'blur', 'keyup', 'enter', 'focus', 'up', 'down'],
  data() {
    return {
      text: this.modelValue,
      placeholderValue: this.placeholder
    };
  },
  watch: {
    modelValue(n) {
      if (n !== this.text) {
        this.text = n;
      }
    }
  },
  mounted() {
    if (this.initialFocus) {
      this.$refs.input.focus();
    }
  },
  methods: {
    onEnter(event) {
      this.$emit('keyup', event);
      this.$emit('enter', event);
    },
    onUp(event) {
      this.$emit('keyup', event);
      this.$emit('up', event);
    },
    onDown(event) {
      this.$emit('keyup', event);
      this.$emit('down', event);
    },
    clear() {
      this.$trackEvent(`clear`);
      this.text = '';
      this.$refs.input.focus();
      this.$emit('change', this.text);
    },
    onBlur(event) {
      this.placeholderValue = this.placeholder;
      this.$emit('blur', event);
    },
    onfocus() {
      this.placeholderValue = '';
      this.$emit('focus');
    },
    notify(e) {
      if (this.debounce) {
        this.debouncedValueChanged(e);
      } else {
        this.valueChanged(e);
      }
    },
    valueChanged(e) {
      this.text = e.target.value;
      this.$emit('change', e.target.value);
      // this.$emit('input', e.target.value);
      this.$emit('update:modelValue', e.target.value);
    },
    debouncedValueChanged: _debounce(function (e) {
      this.text = e.target.value;
      this.$emit('change', e.target.value);
      // this.$emit('input', e.target.value);
      this.$emit('update:modelValue', e.target.value);
    }, 1000)
  }
};
</script>

<style lang="scss" scoped>
div {
  display: grid;
  grid-template-rows: max-content;

  &.has-label {
    grid-template-rows: max-content max-content;
  }

  > * {
    min-width: 0;
    min-height: 0;
  }

  label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
    margin-bottom: 5px;
  }

  input,
  textarea {
    padding: 7px 12px;
    font-size: 0.85rem;
    font-weight: 400;
    border-radius: 2px;
    border: 1px solid var(--theme-on-background-accent);
    color: var(--theme-on-background);
    background: var(--theme-background);
    outline: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:not([disabled]) {
      &:focus,
      &:active {
        border-color: var(--theme-on-background);
        color: var(--theme-on-background);
        outline: none;
      }
    }
  }

  textarea {
    resize: none;
    font-family: inherit;
    min-height: 62px;
  }

  &.disabled {
    opacity: 0.5;
  }

  input[type='text'] {
    width: 100%;
  }

  .input-with-x {
    position: relative;
    .x {
      position: absolute;
      z-index: 10;
      right: 1px;
      top: 5px;
      padding: 3px;
    }
  }
}

.warning {
  color: var(--theme-error);
}
</style>
