export default [
  {
    url: 'pair-authenticator',
    name: 'USPTO login',
    key: 'uspto_login'
  },
  {
    url: 'pair-authenticator',
    name: 'USPTO refresh token',
    key: 'uspto_refresh_token'
  },
  {
    url: 'patent-center',
    name: 'USPTO download application',
    key: 'uspto_download_application'
  },

  {
    url: 'patent-center',
    name: 'USPTO get application data',
    key: 'uspto_get_application_data'
  },
  {
    url: 'patent-center',
    name: 'USPTO get correspondence data',
    key: 'uspto_get_correspondence'
  },
  {
    url: 'patent-center',
    name: 'USPTO get applications list',
    key: 'uspto_get_applications_LIST'
  }
];
