import { am } from './utils';

import httpClient from '@/utils/httpClient';
import logger from '@/utils/logger';

export const LOGIN = am('LOGIN');
export const LOGOUT = am('LOGOUT');
export const INITIALIZATION = am('INITIALIZATION');

export const state = {
  isReady: false,
  isRequestPending: false,
  isRequestFailed: false,

  isAuthenticated: false,

  email: null,
  firstName: null,
  lastName: null,
  userId: null,

  teams: [],

  permissions: null
};

export function decodeJwtToken(token) {
  const [, payload] = token.split('.');
  const { iss, sub, email, firstName, lastName, permissions, teams } = JSON.parse(window.atob(payload));
  return {
    iss,
    sub,
    email,
    firstName,
    lastName,
    permissions,
    teams
  };
}

const getters = {
  token() {
    return localStorage.getItem('HUB_AUTH_TOKEN');
  },
  fullName: state => {
    const firstName = state.firstName;
    const lastName = state.lastName;
    const email = state.email;

    return firstName || lastName ? `${firstName} ${lastName}`.trim() : email;
  },
  initials: state => {
    const firstName = state.firstName;
    const lastName = state.lastName;
    const email = state.email;

    if (firstName && lastName) {
      return `${firstName[0]}${lastName[0]}`.toUpperCase();
    }

    if (firstName) {
      return `${firstName.substring(0, 2)}`.toUpperCase();
    }

    if (lastName) {
      return lastName.substring(0, 2).toUpperCase();
    }

    if (email) {
      return email.substring(0, 2).toUpperCase();
    }

    return '';
  },
  teams: state => {
    return state.teams;
  }
};

export const actions = {
  async initialize({ commit }) {
    commit(LOGIN.STARTED);
    try {
      const token = localStorage.getItem('HUB_AUTH_TOKEN');
      if (token) {
        const token = await httpClient.post(`/api/auth/refresh`);
        localStorage.setItem('HUB_AUTH_TOKEN', token);

        const { email, firstName, lastName, permissions, teams, sub } = decodeJwtToken(token);
        commit(LOGIN.COMPLETED, { email, firstName, lastName, permissions, teams, sub });
      } else {
        commit(LOGOUT.COMPLETED);
      }
    } catch (e) {
      commit(LOGOUT.COMPLETED);
    } finally {
      commit(INITIALIZATION.COMPLETED);
    }
  },
  async login({ commit }, { email, password }) {
    commit(LOGIN.STARTED);
    try {
      const token = await httpClient.post(`/api/auth/login`, { email, password });
      localStorage.setItem('HUB_AUTH_TOKEN', token);

      const { firstName, lastName, permissions, teams, sub } = decodeJwtToken(token);
      commit(LOGIN.COMPLETED, { email, firstName, lastName, permissions, teams: teams, sub });
    } catch (e) {
      logger.error(e);
      commit(LOGIN.FAILED);
    }
  },
  async logout({ commit }) {
    try {
      localStorage.removeItem('HUB_AUTH_TOKEN');
      commit(LOGOUT.COMPLETED);
    } catch (e) {
      logger.error(e);
    }
  }
};

export const mutations = {
  [LOGIN.STARTED](state) {
    state.isRequestPending = true;
    state.isRequestFailed = false;
    state.email = null;
    state.firstName = null;
    state.lastName = null;
    state.permissions = null;
  },
  [LOGIN.FAILED](state) {
    state.isRequestPending = false;
    state.isRequestFailed = true;
  },
  [LOGIN.COMPLETED](state, { email, firstName, lastName, permissions, teams, sub }) {
    state.isRequestPending = false;
    state.isAuthenticated = true;
    state.email = email;
    state.firstName = firstName;
    state.lastName = lastName;
    state.permissions = permissions;
    state.teams = Array.isArray(teams) ? teams : [];
    state.userId = sub;
  },
  [LOGOUT.COMPLETED](state) {
    state.isRequestPending = false;
    state.isAuthenticated = false;
    state.email = null;
    state.firstName = null;
    state.lastName = null;
    state.permissions = null;
  },
  [INITIALIZATION.COMPLETED](state) {
    state.isReady = true;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
