import { createApp } from 'vue';

import Lock from './../components/common/Lock';

export default {
  install(app) {
    app.config.globalProperties.$lock = () => {
      const instance = createApp(Lock, {});

      const release = () => {
        instance && instance.unmount();
      };

      instance.mount(document.getElementById('lock'));

      return {
        release
      };
    };
  }
};
