<template>
  <Popper :hover="true">
    <div class="extended-healthcheck-holder" :class="[indicatorClass]"></div>
    <template #content>
      <ul class="popup-body">
        <li v-for="service in services" :key="service.url">
          <span>{{ service.name }} {{ service.text }}</span>
        </li>
      </ul>
    </template>
  </Popper>
</template>

<script>
import httpClient from '@/utils/httpClient';
import Popper from 'vue3-popper';
import services from './healthchecks';

export default {
  components: {
    Popper
  },
  data() {
    return {
      intervalRef: null,
      services: services.map(s => ({ ...s, lastSuccess: null }))
    };
  },
  computed: {
    indicatorClass() {
      const statuses = this.services.map(service => service.isHealthy);
      if (statuses.includes('down')) {
        return 'down';
      } else if (statuses.includes('warn')) {
        return 'warn';
      } else {
        return 'up';
      }
    }
  },
  async created() {
    await this.executeHealthCheck();
    this.intervalRef = setInterval(this.executeHealthCheck, 60 * 1000);
  },
  unmounted() {
    clearInterval(this.intervalRef);
  },

  methods: {
    async executeHealthCheck() {
      const checkPromises = this.services.map(service => {
        const currentTime = new Date();
        const tenMinutesAgo = new Date(currentTime.getTime() - 10 * 60 * 1000);
        const formattedTime = tenMinutesAgo.toISOString();
        return httpClient
          .get(`/api/${service.url}/health/extended/history?from=${formattedTime}&key=${service.key}_unhealthy`)
          .then(response => {
            service.history = response;
            if (response.length) {
              if (!response[0].endTime) {
                service.isHealthy = 'down';
                service.text = 'is down';
              } else {
                service.isHealthy = 'warn';
                service.text = 'was down during last 10 minutes';
              }
            } else {
              service.isHealthy = 'up';
              service.text = 'is up';
            }
            // service.isHealthy = response.ok;
          })
          .catch(() => {
            service.isHealthy = 'down';
            service.text = 'is down';
          });
      });
      await Promise.all(checkPromises);
    }
  }
};
</script>

<style scoped>
.popup-body {
  text-transform: lowercase;
  text-align: left;
}
.extended-healthcheck-holder {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: green;
  &.warn {
    background-color: var(--theme-warning);
  }
  &.up {
    background-color: var(--theme-success);
  }
  &.down {
    background-color: var(--theme-error);
  }
}
/* Your component's CSS styles go here */
</style>
