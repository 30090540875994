import { am } from './utils';

import httpClient, { jsonObjectToFormData } from '@/utils/httpClient';

const INVENTION_FILES_LOAD = am(`INVENTION_FILES_LOAD`);

export default {
  namespaced: true,
  state: {
    files: [],
    isLoadingFiles: true
  },

  mutations: {
    [INVENTION_FILES_LOAD.STARTED](state) {
      state.isLoadingFiles = true;
      state.files = [];
    },
    [INVENTION_FILES_LOAD.COMPLETED](state, files) {
      state.isLoadingFiles = false;
      state.files = files;
    },
    tagDeleted(state, { fileId, tag }) {
      const file = state.files.find(file => file.id === fileId);
      file.tags = file.tags.filter(t => t !== tag);
    },
    filesUploaded(state, { files, type }) {
      const newFiles = files.map(file => ({
        id: Math.random() * 1000000,
        name: file.name,
        createdAt: new Date(),
        tags: [type]
      }));
      state.files = [...state.files, ...newFiles];
    },
    documentUpdated(state, document) {
      const fileIndex = state.files.findIndex(file => file.id === document.id);
      state.files[fileIndex] = { ...state.files[fileIndex], ...document };
    },
    documentDeleted(state, documentId) {
      const doc = state.files.find(file => file.id === documentId);
      doc.isDeleted = true;
    }
  },
  actions: {
    async loadFiles({ commit }, references) {
      commit(INVENTION_FILES_LOAD.STARTED);
      const files = await httpClient.post('/api/filesmanager/files/getbyreference', {
        reference: references
      });

      commit(INVENTION_FILES_LOAD.COMPLETED, files);
      return files;
    },

    async updateDocument({ commit }, { id, document }) {
      const doc = await httpClient.patch(`/api/filesmanager/files/${id}`, {
        ...document
      });
      commit('documentUpdated', doc);
    },
    async uploadFiles({ dispatch }, payload) {
      await httpClient.request('/api/filesmanager/files/file', {
        json: false,
        method: 'POST',
        body: jsonObjectToFormData(payload)
      });
      dispatch('loadFiles', payload.references);
    },
    async markFilesDeleted({ commit }, documentIds) {
      for (const documentId of documentIds) {
        await httpClient.delete(`/api/filesmanager/files/${documentId}`);
        commit('documentDeleted', documentId);
      }
    }
  }
};
