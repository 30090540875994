import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';
const GET_DIR_INDEX = am(`GET_DIR_INDEX`);
const RE_INDEX = am(`RE_INDEX`);

export default {
  namespaced: true,
  ...factory({
    state: {
      loading: false,
      index: {},
      failed: false
    },
    getters: {
      root: state => reference => {
        return state.index[reference]?.path;
      }
    },
    mutations: {
      [GET_DIR_INDEX.STARTED](state) {
        state.loading = true;
        state.failed = false;
      },
      [GET_DIR_INDEX.COMPLETED](state, index) {
        const indexMap = index.reduce((acc, curr) => {
          acc[curr.reference] = curr;
          return acc;
        }, {});

        state.index = indexMap;
        state.loading = false;
        state.failed = false;
      },
      [GET_DIR_INDEX.FAILED](state) {
        state.index = {};
        state.loading = false;
        state.failed = true;
      },
      [RE_INDEX.STARTED](state) {
        state.loading = true;
        state.failed = false;
      },
      [RE_INDEX.COMPLETED](state, { reference, path }) {
        state.index[reference] = { reference, path };
        state.loading = false;
        state.failed = false;
      },
      [RE_INDEX.FAILED](state) {
        state.loading = false;
        state.failed = true;
      }
    },
    actions: {
      async initialize({ commit }) {
        try {
          commit(GET_DIR_INDEX.STARTED);
          const index = await httpClient.get(`/api/documents-repository-connector/index`);
          commit(GET_DIR_INDEX.COMPLETED, index);
        } catch {
          commit(GET_DIR_INDEX.FAILED);
        }
      },
      async getTree(obj, reference) {
        if (!reference) {
          return null;
        }

        try {
          return await httpClient.get(`/api/documents-repository-connector/index/${reference}`);
        } catch (error) {
          return null;
        }
      },
      async sync({ commit }, reference) {
        try {
          commit(RE_INDEX.STARTED);
          const token = await httpClient.post(`/api/documents-repository-connector/sync/enqueue/${reference}`);
          if (!token) {
            commit(RE_INDEX.FAILED);
            return;
          }

          let retry = 10;

          while (retry > 0) {
            try {
              await new Promise(resolve => setTimeout(resolve, 1000));
              const response = await httpClient.get(`/api/documents-repository-connector/sync/token/${token.id}`);
              if (response?.fullfiled) {
                if (response.status === 'succeeded') {
                  commit(RE_INDEX.COMPLETED, { reference, path: response.root });
                } else {
                  commit(RE_INDEX.FAILED);
                }
                return response;
              }
            } catch (error) {
              // swallow
            } finally {
              retry--;
            }
          }
          commit(RE_INDEX.FAILED);
          return null;
        } catch (e) {
          commit(RE_INDEX.FAILED);
          throw e;
        }
      }
    }
  })('directoryIndex', {
    async getCollection() {
      throw new Error('Not implemented.');
    },
    async getById() {
      throw new Error('Not implemented.');
    },
    async delete() {
      throw new Error('Not implemented.');
    },
    async update() {
      throw new Error('Not implemented.');
    }
  })
};
