import { am } from './utils';

import httpClient from '@/utils/httpClient';

export const GET_NOTES_FOR_INVENTION = am('GET_NOTES_FOR_INVENTION');
export const CREATE_NOTES_FOR_INVENTION = am('CREATE_NOTES_FOR_INVENTION');
export const UPDATE_NOTES_FOR_INVENTION = am('UPDATE_NOTES_FOR_INVENTION');
export const DELETE_NOTES_FOR_INVENTION = am('DELETE_NOTES_FOR_INVENTION');

export const state = {
  notes: {}
};

export const actions = {
  async getNotesForInvention({ commit }, inventionId) {
    commit(GET_NOTES_FOR_INVENTION.STARTED, inventionId);
    const collection = await httpClient.get(`/api/inventions/invention-notes/${inventionId}`);
    commit(GET_NOTES_FOR_INVENTION.COMPLETED, { id: inventionId, collection });
  },
  async createNote({ commit }, payload) {
    const note = await httpClient.post(`/api/inventions/invention-notes`, payload);
    commit(CREATE_NOTES_FOR_INVENTION.COMPLETED, note);
  },
  async updateNote({ commit }, { id, payload }) {
    const note = await httpClient.patch(`/api/inventions/invention-notes/${id}`, payload);
    commit(UPDATE_NOTES_FOR_INVENTION.COMPLETED, note);
  },
  async deleteNote({ commit }, { id, inventionId }) {
    await httpClient.delete(`/api/inventions/invention-notes/${id}`);
    commit(DELETE_NOTES_FOR_INVENTION.COMPLETED, { id, inventionId });
  }
};

export const mutations = {
  [GET_NOTES_FOR_INVENTION.STARTED](state, id) {
    state.notes[id] = {
      isRequestPending: true,
      collection: []
    };
  },
  [GET_NOTES_FOR_INVENTION.COMPLETED](state, { id, collection }) {
    state.notes[id] = {
      isRequestPending: false,
      collection: collection
    };
  },
  [CREATE_NOTES_FOR_INVENTION.COMPLETED](state, note) {
    state.notes[note.inventionId].collection.push(note);
  },
  [UPDATE_NOTES_FOR_INVENTION.COMPLETED](state, note) {
    const index = state.notes[note.inventionId].collection.findIndex(n => n.id === note.id);
    state.notes[note.inventionId].collection[index] = note;
  },
  [DELETE_NOTES_FOR_INVENTION.COMPLETED](state, { id, inventionId }) {
    const index = state.notes[inventionId].collection.findIndex(n => n.id === id);
    state.notes[inventionId].collection.splice(index, 1);
  }
};

export default {
  namespaced: true,
  state,

  mutations,
  actions
};
