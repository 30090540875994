<template>
  <div v-if="visible" class="modal-overlay">
    <section class="modal">
      <header>
        <div>
          <h2>
            <slot name="title"></slot>
          </h2>
        </div>
        <div>
          <slot name="actions"></slot>
        </div>
        <div>
          <hub-button v-if="closable" variant="icon" class="close-button" @click="$emit('close')"><hub-icon name="close"></hub-icon></hub-button>
        </div>
      </header>
      <div>
        <slot />
      </div>
      <footer>
        <slot name="footer"></slot>
      </footer>
    </section>
  </div>
</template>

<script>
import Button from './Button';
import Icon from './Icon';
export default {
  components: {
    'hub-icon': Icon,
    'hub-button': Button
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    closable: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  data() {
    return {};
  },
  watch: {
    visible: {
      handler() {},
      immediate: true
    }
  }
};
</script>

<style lang="scss">
.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 6;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.6);
  transition: 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: opacity;
  will-change: opacity;
}
.modal {
  > header {
    background-color: var(--theme-surface);
    display: grid;
    grid-template-columns: minmax(0, 1fr) max-content max-content;
    align-items: center;
    padding: 0.75rem 2rem 0.75rem 2rem;
    h2 {
      font-size: 1.2rem;
      font-weight: 500;
      text-transform: uppercase;
      padding: 0;
      color: var(--theme-primary);
    }
  }
  > div {
    overflow-y: auto;
    overflow-x: auto;
  }

  > footer {
    padding: 0.75rem 2rem 0.75rem 2rem;
    display: flex;
    justify-content: flex-end;

    > * {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
  display: grid;
  grid-template-rows: max-content 300px max-content;
  max-width: 90%;
  min-width: 40%;
  max-height: 90%;
  min-height: 40%;
  background: var(--theme-background);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  transition: opacity 0.15s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 7;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}
@media screen and (max-device-width: 1023px), screen and (max-width: 1023px) {
  .modal {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
    background: var(--theme-background);
    transform: initial;
    grid-template-rows: max-content max-content max-content;
  }
}
</style>
