import {
  startOfTomorrow,
  startOfToday,
  addDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  startOfDay,
  endOfDay,
  addWeeks,
  addMonths,
  addBusinessDays
} from 'date-fns';

export default () => [
  { name: 'overdue', from: new Date('2020-01-01'), to: startOfToday() },
  { name: 'today', from: startOfToday(), to: startOfTomorrow() },
  { name: 'tomorrow', from: startOfTomorrow(), to: addDays(startOfTomorrow(), 1) },
  { name: 'next 3 days', from: startOfToday(), to: addDays(startOfToday(), 4) },
  { name: 'this week', from: startOfWeek(new Date()), to: addDays(endOfWeek(new Date()), 1) },
  { name: 'no due date', from: null, to: null }
];

export function convertCustomRange({ from = {}, to = {} }) {
  let fromResult, toResult;
  if (from.refDate) {
    fromResult = getPrepareFunction(from.refDate)(new Date());
    if (from.offset && from.unit) {
      fromResult = addOffset(fromResult, from.offset, from.unit);
    }
    fromResult = startOfDay(fromResult);
  }
  if (to.refDate) {
    toResult = getPrepareFunction(to.refDate)(new Date());
    if (to.offset && to.unit) {
      toResult = addOffset(toResult, to.offset, to.unit);
    }
    toResult = endOfDay(toResult);
  }
  return { from: fromResult, to: toResult };
}
function getPrepareFunction(refDate) {
  switch (refDate) {
    case 'Today':
      return startOfToday;
    case 'Start of this week':
      return startOfWeek;
    case 'Start of this month':
      return startOfMonth;
    case 'Start of this year':
      return startOfYear;
    case 'End of this week':
      return endOfWeek;
    case 'End of this month':
      return endOfMonth;
    case 'End of this year':
      return endOfYear;
  }
}

function addOffset(date, offset, unit) {
  switch (unit) {
    case 'day':
      return addDays(date, offset);
    case 'business day':
      return addBusinessDays(date, offset);
    case 'week':
      return addWeeks(date, offset);
    case 'month':
      return addMonths(date, offset);
    default:
      throw new Error(`Unsupported unit: ${unit}`);
  }
}
