import { factory, am } from './utils';

const INITIALIZE_LIST = am(`GET_FILTER_BY_ID`);
const GET_TASK_COLLECTION_BY_LIST_ID = am(`GET_COLLECTION`);

import httpClient from '@/utils/httpClient';
import dueAt, { convertCustomRange } from '@/components/common/dueAt';

function createFilter(context, state, id, isExclude) {
  let exclude = [];
  if (context.catchAll && !isExclude) {
    const otherFilters = state.collection.filter(filter => filter.id !== id);
    exclude = otherFilters.map(filter => createFilter(filter.filter, state, filter.id, true));
  }

  let from, to;
  const dueAtValue = context.dueAt;
  if (typeof dueAtValue === 'string') {
    const settings = dueAt().find(item => item.name === context.dueAt) || {};
    from = settings.from;
    to = settings.to;
  } else if (dueAtValue && (dueAtValue.from || dueAtValue.to)) {
    const convertResult = convertCustomRange(dueAtValue);
    from = convertResult.from;
    to = convertResult.to;
  }

  const dueAtArr = [];
  if (from) {
    dueAtArr.push('from:' + from.toISOString());
  }
  if (to) {
    dueAtArr.push('to:' + to.toISOString());
  }

  let statusActiveFromFrom, statusActiveFromTo;
  const statusActiveFromValue = context.statusActiveFrom;
  if (typeof statusActiveFromValue === 'string') {
    const settings = dueAt().find(item => item.name === context.statusActiveFrom) || {};
    statusActiveFromFrom = settings.from;
    statusActiveFromTo = settings.to;
  } else if (statusActiveFromValue && (statusActiveFromValue.from || statusActiveFromValue.to)) {
    const convertResult = convertCustomRange(statusActiveFromValue);
    statusActiveFromFrom = convertResult.from;
    statusActiveFromTo = convertResult.to;
  }

  const statusActiveFromArr = [];
  if (statusActiveFromFrom) {
    statusActiveFromArr.push('from:' + statusActiveFromFrom.toISOString());
  }
  if (statusActiveFromTo) {
    statusActiveFromArr.push('to:' + statusActiveFromTo.toISOString());
  }

  return {
    qs: context.qs,
    status: context.status,
    assignees: context.assignees,
    projectAssignees: context.projectAssignees,
    assigneesConjugation: context.assigneesConjugation || 'or',
    dueAt: dueAtArr.length ? dueAtArr : undefined,
    statusActiveFrom: statusActiveFromArr.length ? statusActiveFromArr : undefined,
    workflows: context.workflows,
    tags: context.tags,
    tagsConjugation: context.tagsConjugation || 'or',
    exclude,
    groupBy: context.groupBy,
    sortBy: context.sortBy,
    order: context.order
  };
}

export default {
  namespaced: true,
  ...factory({
    state: {},
    mutations: {},
    actions: {}
  })(
    'boards',
    {
      async getById(id) {
        const item = await httpClient.get(`/api/workflow/boards/${id}`);
        return {
          ...item,
          filters: item.filters.filter(s => !!s)
        };
      },
      async getCollection() {
        return httpClient.get(`/api/workflow/boards/`);
      },
      async create(data) {
        const board = await httpClient.post(`/api/workflow/boards/`, data);
        return board;
      },
      async update(id, data) {
        return await httpClient.patch(`/api/workflow/boards/${id}`, data);
      },
      async delete(id) {
        return httpClient.delete(`/api/workflow/boards/${id}`);
      },
      async clone(board) {
        return httpClient.post(`/api/workflow/boards/${board.id}/clone`);
      }
    },
    {
      lists: {
        namespaced: true,

        state: {
          collection: [],
          tasks: {
            isGetCollectionRequestPending: true,
            collection: [],
            total: 0,
            skip: 0,
            size: 0
          }
        },
        mutations: {
          clear(state) {
            state.collection = [];
          },
          [INITIALIZE_LIST.STARTED]() {},
          [INITIALIZE_LIST.COMPLETED](state, filter) {
            const item = state.collection.find(item => item.id === filter.id);
            if (item) {
              item.title = filter.title;
              item.layout = filter.layout;
              item.data = [];
              item.total = 0;
              item.filter = filter.data;
            } else {
              state.collection.push({
                id: filter.id,
                layout: filter.layout,
                type: filter.type,
                title: filter.title,
                isPending: true,
                total: 0,
                data: [],
                filter: filter.data
              });
            }
          },
          [INITIALIZE_LIST.FAILED](state, filter) {
            const item = state.collection.find(item => item.id === filter.id);
            if (item) {
              // do nothing
            } else {
              state.collection.push({
                id: filter.id,
                isFailed: true,
                total: 0,
                data: []
              });
            }
          },
          [GET_TASK_COLLECTION_BY_LIST_ID.STARTED](state, { id }) {
            for (const item of state.collection) {
              if (item.id === id) {
                item.isPending = true;
              }
            }
          },
          [GET_TASK_COLLECTION_BY_LIST_ID.COMPLETED](state, { id, data, total, refresh, isGrouped }) {
            for (const board of state.collection) {
              if (board.id !== id) {
                continue;
              }

              board.isPending = false;
              board.total = total;

              if (refresh) {
                board.data = [];
              }
              // debugger;
              if (isGrouped) {
                for (const item of data) {
                  const existingGroup = board.data.find(({ group }) => item.group === group);
                  if (existingGroup) {
                    for (const task of item.data) {
                      if (!existingGroup.data.find(({ id }) => task.id === id)) {
                        existingGroup.data.push(task);
                      }
                    }
                    // debugger;
                  } else {
                    board.data.push(item);
                  }
                }
              } else {
                for (const item of data) {
                  if (board.data.find(({ id }) => item.id === id)) {
                    continue;
                  }
                  board.data.push(item);
                }
              }
            }
          },
          [GET_TASK_COLLECTION_BY_LIST_ID.FAILED](state, { id }) {
            for (const board of state.collection) {
              if (board.id !== id) {
                continue;
              }
              board.isPending = false;
              board.isFailed = true;
            }
          }
        },
        actions: {
          async getById({ commit, rootGetters }, { id }) {
            try {
              commit(INITIALIZE_LIST.STARTED);
              const filter = rootGetters['filters/byId'](id);

              if (!filter) {
                commit(INITIALIZE_LIST.FAILED, { id });
              } else {
                commit(INITIALIZE_LIST.COMPLETED, filter);
              }
            } catch (e) {
              commit(INITIALIZE_LIST.FAILED, { id });
            }
          },
          async exec({ commit, state }, { id, skip = 0, size, refresh, searchAs }) {
            try {
              commit(GET_TASK_COLLECTION_BY_LIST_ID.STARTED, { id });

              const context = (await httpClient.get(`/api/workflow/filters/${id}`))?.data;

              const response = await httpClient.post('/api/search/tasks?', {
                ...createFilter(context, state, id),
                skip,
                size: size || 25,
                searchAs,
                sort: null
              });

              commit(GET_TASK_COLLECTION_BY_LIST_ID.COMPLETED, {
                id,
                data: response.data.map(item => ({ ...item, createdAt: new Date(item.createdAt) })),
                total: response.total,
                refresh,
                isGrouped: !!context.groupBy
              });
            } catch (e) {
              commit(GET_TASK_COLLECTION_BY_LIST_ID.FAILED, { id });
              throw e;
            }
          }
        }
      }
    }
  )
};
