<template>
  <section v-if="message" class="toast" :class="[type]">
    <label v-if="type === 'success'" class="toast-type">&#10004;</label>
    <label v-if="type === 'error'" class="toast-type">&#10008;</label>

    <span class="toast-close" @click="close">&times;</span>
    <header class="toast-content">
      <h3 v-if="title" class="toast-title">{{ title }}</h3>
      <div v-if="message" class="toast-message">{{ message }}</div>
    </header>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => null
    },
    message: {
      type: String,
      default: () => null
    },
    type: {
      type: String,
      default: () => null
    }
  },
  emits: ['close'],
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.toast {
  display: inline-flex;
  position: relative;
  min-width: 240px;
  max-width: 360px;
  padding: 12px 50px;
  border-radius: 2px;
  opacity: 0.9;
  background-color: lightgray;
  color: darkslategrey;
  font-family: Roboto;

  &.error {
    background-color: var(--theme-error);
    color: var(--theme-on-error);
  }

  &.success {
    background-color: var(--theme-success);
    color: var(--theme-on-success);
  }

  .toast-content {
    .toast-title {
      margin: 0 0 5px;
      padding: 0;
      font-size: 13px;
      font-weight: 500;
      background-color: transparent;
    }

    .toast-message {
      margin: 0;
      padding: 0;
      font-size: 13px;
      font-weight: normal;
    }
  }

  .toast-type {
    position: absolute;
    top: 50%;
    left: 17px;
    transform: translateY(-50%);
  }
  .toast-close {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
</style>
